<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-building-o"></i> Company</b-card-title>
			<b-card-sub-title>Manages the company registration</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Region">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.regionItems"
												:reduce="(region) => region.value" v-model="filterBy.region">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for region
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Area">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.areaItems" :reduce="(area) => area.value"
												v-model="filterBy.area">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for area
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.companyItems"
												:reduce="(company) => company.value" v-model="filterBy.parentCompany">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - " :options="filterByOptions.statusItems"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Industry">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.industryItems"
												:reduce="(industry) => industry" v-model="filterBy.industry">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-company v-show="!isViewer">
								Add Company
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Companies in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv"
									:name="fileName + '.csv'">
									Export Companies to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="companiesTable" show-empty striped hover :items="items" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

					<template v-slot:cell(description)="row">
						<span class="truncate-text">
							<truncate collapsed-text-class="collapsed" clamp="Show More" :length="50" less="Show Less"
								type="html" :text="breakDescription(row.item.description, 25)" />
						</span>
					</template>

					<template v-slot:cell(parentCompany)="row">
						{{ row.item.parentCompany ? row.item.parentCompany : 'N/A' }}
					</template>

					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
							<b-button v-show="isAllowedToEdit(row.item)" size="sm" v-b-modal.edit-company
								v-b-tooltip.hover.top="'Edit Details'" variant="warning"
								@click.stop="updateSelCompany(row.item, row.index, $event.target)" class="mr-1">
								<i class="fa fa-pencil"></i>
							</b-button>

							<b-button v-show="isAllowedToSendWelcomeEmail(row.item)" size="sm"
								v-b-tooltip.hover.top="'Send Welcome Notification'" variant="primary" class="mr-1"
								@click="openEmailConfirmation(row.item)">
								<i class="fa fa-send"></i>
							</b-button>
						</span>
					</template>

					<template v-slot:cell(status)="row">
						<a @click="updateSelCompany(row.item)" v-if="isAllowedToActivate(row.item)">
							<b-form-checkbox name="status" v-b-modal.activate-company v-model="row.item.isActive" switch
								size="sm">
								{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
							</b-form-checkbox>
						</a>
						<span v-else>
							{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<CompanyDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<SendEmailNotification :item="companyDetails" />
		<AddCompany :allIndustriesObj="allIndustriesObj" />
		<EditCompany :allIndustriesObj="allIndustriesObj" />
		<ActivateCompany />
		<CompanyLogoImageView :imageDetails="imageDetails" />
	</div>
</template>

<script>
// Component
import AddCompany from './company/AddCompany';
import EditCompany from './company/EditCompany';
import SendEmailNotification from './company/SendEmailNotification';
import ActivateCompany from './company/ActivateCompany';
import CompanyDetailsView from './company/CompanyDetailsView';
import CompanyLogoImageView from './company/CompanyLogoImageView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// DAO & API
import industryDAO from '@/database/industries';
import companyApi from '@/api/companyApi';
import storageLocationApi from '@/api/storageLocationApi';
import connectionApi from '@/api/connectionApi';
import userApi from '@/api/userApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'company',
	components: {
		AddCompany,
		EditCompany,
		SendEmailNotification,
		ActivateCompany,
		CompanyDetailsView,
		CompanyLogoImageView,
		Loading,
		JsonExcel,
		truncate
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'name',
					sortable: true,
				},
				{
					key: 'description',
					sortable: false,
				},
				{
					key: 'type',
					sortable: true,
				},
				{
					key: 'industry',
					sortable: true,
				},
				{
					key: 'parentCompany',
					sortable: true,
				},
				'status',
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				parentCompany: { ...config.companyDefaultValue },
				industry: { ...config.dropdownDefaultValue },
				isActive: null,
				region: { ...config.dropdownDefaultValue },
				area: { ...config.dropdownDefaultValue },
			},
			filterBy: {
				parentCompany: { ...config.companyDefaultValue },
				industry: { ...config.dropdownDefaultValue },
				isActive: null,
				region: { ...config.dropdownDefaultValue },
				area: { ...config.dropdownDefaultValue },
			},
			prevFilterBy: {},

			filterByOptions: {
				regionItems: [],
				areaItems: [],
				companyItems: [],
				industryItems: [],
				statusItems: config.statusOptionsWithDefault
			},

			allRegionsObj: {},
			allAreasObj: {},
			allIndustriesObj: {},
			allCompaniesObj: {},
			selCompany: {},
			companyDetails: {},
			imageDetails: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},

	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			let fields = {
				Name: 'name',
				Description: 'description',
				Type: 'type',
				Industry: 'industry',
				Address: 'address',
				'Region': 'region',
				'Area': 'area',
				'Contact Person': 'contactPerson',
				'Contact Number': 'contactNo',
				Email: 'emailAddress',
				Brand: 'brand',
				'Branch Code': 'branchCode',
				'Parent Company': 'parentCompany',
				'Number of Users': 'noOfUsers',
				'Number of Storage Locations': 'noOfStorageLocations',
				'Number of Inactive Nodes': 'noOfInactiveNodes',
			};

			if (this.isSuperAdmin) {
				fields["Has 2-Way Auth?"] = 'Has 2-Way Auth?';
				fields["Use Top Camera?"] = 'hasCameraTop';
				fields["Use Back Camera?"] = 'hasCameraBack';
				fields["Has CMS Access?"] = 'hasCms';
				fields["Has Dispatch Access?"] = 'hasDispatch';
				fields["Has Inventory Access?"] = 'hasInventory';
				fields["Has Sticker Access?"] = 'hasSticker';
			}

			fields['Status'] = 'status';
			fields['Date Created'] = 'Date Created';
			fields['Date Updated'] = 'Date Updated';
			fields['Created By'] = 'createdBy';
			fields['Updated By'] = 'updatedBy';

			return fields;
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Company-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},

	watch: {
		"filterBy.region": function (newVal) {
			if (newVal !== null) {
				let filteredAreasObj = _.filter(this.allAreasObj, o => {
					return o.region === newVal;
				});
				this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreas(filteredAreasObj);
			} else {
				this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreas(this.allAreasObj);
			}

			// reset area
			this.filterBy.area = null;
		}
	},

	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner
					|| this.$store.getters.isAccounting
					|| this.$store.getters.isApprover
					|| this.$store.getters.isMaintenance) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				this.allIndustriesObj = await industryDAO.getAllIndustries();
				this.filterByOptions.industryItems = DropDownItemsUtil.retrieveIndustries(
					this.allIndustriesObj,
					false
				);

				this.allRegionsObj = { ...this.$store.getters.regions };
				this.filterByOptions.regionItems = DropDownItemsUtil.retrieveRegions(this.allRegionsObj);

				this.allAreasObj = { ...this.$store.getters.areas };
				this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreas(this.allAreasObj);

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.filterByOptions.companyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseAddCompany', (data) => {
			// update cache for dependent data updates
			this.$store.dispatch('updateAllStorageLocations', data.storageLocations);
			this.$store.dispatch('updateAllConnections', data.connections);

			// proceed updating the table
			this.updateTable(data.company);
		});
		EventBus.$on('onCloseEditCompany', (data) => {
			// update cache for dependent data updates
			_.forEach(data.subCompanies, (subCompany) => {
				this.allCompaniesObj[subCompany.id] = subCompany;
			});
			this.$store.dispatch('updateAllStorageLocations', data.storageLocations);
			this.$store.dispatch('updateAllUsers', data.users);
			this.$store.dispatch('updateAllConnections', data.connections);

			// proceed updating the table
			this.updateTable(data.company);
		});
		EventBus.$on('onCloseActivateCompany', (companyObj) => {
			// proceed updating the table
			this.updateTable(companyObj);
		});
		EventBus.$on('onEmailSent', (companyObj) => {
			this.updateTable(companyObj);

			// reset company details for notification
			this.companyDetails = {};
		});
	},
	methods: {
		updateTable(companyObj) {
			if (!_.isEmpty(companyObj)) {
				this.allCompaniesObj[companyObj.id] = companyObj;
				this.$store.dispatch('updateAllCompanies', this.allCompaniesObj);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObjs = { ...this.allCompaniesObj };

			_.forEach(filteredObjs, (company, companyId) => {

				if (
					this.filterBy.region &&
					this.filterBy.region.length > 0 &&
					this.filterBy.region !== company.region
				) {
					delete filteredObjs[companyId];
				}

				if (
					this.filterBy.area &&
					this.filterBy.area.length > 0 &&
					this.filterBy.area !== company.area
				) {
					delete filteredObjs[companyId];
				}

				if (
					this.filterBy.parentCompany.id !== null &&
					this.filterBy.parentCompany.id !== company.parentCompanyId &&
					this.filterBy.parentCompany.id !== company.id
				) {
					delete filteredObjs[companyId];
				}

				if (this.filterBy.isActive !== null &&
					this.filterBy.isActive !== company.isActive) {
					delete filteredObjs[companyId];
				}

				if (
					this.filterBy.industry.value !== null &&
					this.filterBy.industry.value !== company.industry
				) {
					delete filteredObjs[companyId];
				}
			});

			this.processCompanies(filteredObjs);
		},
		processCompanies(companiesObj) {
			this.items = Object.values(companiesObj);

			_.forEach(this.items, item => {
				item['status'] = item.isActive === 'true' ? 'Active' : 'Inactive';
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
				item['Has 2-Way Auth?'] = item.has2WayAuth ? 'YES' : 'NO';
				item['hasCameraTop'] = item.camera && item.camera.top ? 'YES' : 'NO';
				item['hasCameraBack'] = item.camera && item.camera.back ? 'YES' : 'NO';

				let permissions = item.permissions;
				item['hasCms'] = permissions.hasOwnProperty('cms') && item.permissions.cms ? 'YES' : 'NO';
				item['hasDispatch'] = permissions.hasOwnProperty('dispatch') && item.permissions.dispatch ? 'YES' : 'NO';
				item['hasInventory'] = permissions.hasOwnProperty('inventory') && item.permissions.inventory ? 'YES' : 'NO';
				item['hasSticker'] = permissions.hasOwnProperty('sticker') && item.permissions.sticker ? 'YES' : 'NO';

				// remove show details
				delete item._showDetails;
			});
			this.items = _.sortBy(this.items, ['name']);
			this.totalRows = this.items.length;

			this.$store.dispatch('updateAllCompanies', companiesObj);

			// refresh table
			if (this.$refs.companiesTable) {
				this.$refs.companiesTable.refresh();
			}
		},

		async onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilterBy)) {
				await this.retrieveData();
				this.prevFilterBy = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilterBy = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.filterTableContent();
			}
		},
		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.withParentCompany = true;
				filter.companyId = this.loggedUserCompany.id;

				if (this.isSuperAdmin) {
					const { data } = await companyApi.getCompanies(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allCompaniesObj = _.assign(this.allCompaniesObj, data.companies);
				} else {
					const { data } = await companyApi.getCompanies(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allCompaniesObj = _.assign(this.allCompaniesObj, data.companies);
				}

				this.filterTableContent();
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		updateSelCompany(item) {
			this.selCompany = { ...item };
			this.$store.commit('SET_CURR_COMPANY', this.selCompany);
			EventBus.$emit('onUpdateSelCompany', this.selCompany);
		},
		async openEmailConfirmation(details) {
			try {
				// show loading indicator
				this.isLoading = true;

				let results = await this.loadCompanyDataFromDatabase(details.id);
				let storageLocations = results[0];
				let connections = results[1];
				let users = results[2];

				this.companyDetails = {
					companyDetails: details,
					storageLocations: Object.values(storageLocations),
					connections: Object.values(connections),
					users: Object.values(users),
				};

				this.$bvModal.show('send-email-notification');
			} catch (_error) {
				this.$toaster.error('Error sending email notification. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		async loadCompanyDataFromDatabase(companyId) {
			let filter = {
				isActive: 'true',
				company: { id: companyId },
				companyId: companyId,
			};

			let storageLocationResults = storageLocationApi.getStorageLocations(
				filter,
				config.view.COMPANY,
				this.loggedUser.id
			);
			let connectionResults = connectionApi.getConnections(
				filter,
				config.view.COMPANY,
				this.loggedUser.id
			);
			let userResults = userApi.getUsers(
				filter,
				config.view.COMPANY,
				this.loggedUser.id
			);
			return Promise.all([
				storageLocationResults,
				connectionResults,
				userResults,
			]).then((results) => {
				let storageLocations = results[0].data.storageLocations;
				let connections = results[1].data.connections;
				let users = results[2].data.users;
				return Promise.all([storageLocations, connections, users]);
			});
		},

		isAllowedToActivate(company) {
			return !this.isViewer && !this.isParentCompany(company) && !this.isAdminCompany(company)
				&& this.loggedUserCompany.id !== company.id;
		},
		isAllowedToEdit(item) {
			return !this.isViewer && (!this.isParentCompany(item) || this.isSuperAdmin);
		},
		isAllowedToSendWelcomeEmail(item) {
			return item.companySetupStatus
				&& !item.companySetupStatus.emailSent
				&& item.companySetupStatus.hasConnection
				&& item.companySetupStatus.hasStorageLocation
				&& item.companySetupStatus.hasActiveUser
				&& (!this.isViewer && (!this.isParentCompany(item) || this.isSuperAdmin));
		},
		isParentCompany(company) {
			return this.loggedUserCompany.parentCompanyId === company.id;
		},
		isAdminCompany(company) {
			return company.name === 'TAWITECH, INC.';
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		breakDescription(description, length) {
			return description.length > length ? description.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : description;
		}
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddCompany');
		EventBus.$off('onCloseEditCompany');
		EventBus.$off('onEmailSent');
	},
};
</script>
