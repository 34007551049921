<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Primary Information" active>
					<b-card-text>
						<b-form-group label="Name" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.name }}
						</b-form-group>

						<b-form-group label="Description" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span class="truncate-text">
								<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100"
									less="Show Less" type="html" :text="row.item.description" />
							</span>
						</b-form-group>

						<b-form-group label="Type" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.type }}
						</b-form-group>

						<b-form-group label="Industry" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.industry }}
						</b-form-group>

						<b-form-group v-if="row.item.parentCompany != null &&
							row.item.parentCompany.length > 0
						" label="Parent Company" label-cols-sm="3" label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ row.item.parentCompany }}
						</b-form-group>

						<b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ getFormattedDateWithTime(row.item.dateCreated) }}
						</b-form-group>

						<b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ getFormattedDateWithTime(row.item.dateUpdated) }}
						</b-form-group>

						<b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span v-if="row.item.isActive === 'true'">
								<b-badge variant="success">Active</b-badge>
							</span>
							<span v-else>
								<b-badge variant="secondary">Inactive</b-badge>
							</span>
						</b-form-group>
					</b-card-text>
				</b-tab>
				<b-tab title="Address">
					<b-card-text>
						<b-form-group label="Address" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.address ? row.item.address : '-' }}
						</b-form-group>

						<b-form-group label="Geoaddress" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showCompanyLocation(row.item)" variant="dark" class="mr-1">
								<i class="fa fa-map-marker fa-lg"></i>
							</b-button>
							<span class="numFont">
								{{ getCoordinates(row.item.geoaddress) }}
							</span>
						</b-form-group>

						<b-form-group label="Region:" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.region ? row.item.region : '-' }}
						</b-form-group>

						<b-form-group label="Area:" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.area ? row.item.area : '-' }}
						</b-form-group>
					</b-card-text>
				</b-tab>
				<b-tab title="Contact Details">
					<b-card-text>
						<b-form-group label="Contact Person" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.contactPerson ? row.item.contactPerson : '-' }}
						</b-form-group>
						<b-form-group label="Email" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.emailAddress ? row.item.emailAddress : '-' }}
						</b-form-group>
						<b-form-group label="Contact No." label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span class="numFont">
								{{ row.item.contactNo ? row.item.contactNo : '-' }}
							</span>
						</b-form-group>
					</b-card-text>
				</b-tab>
				<b-tab title="Subscription">
					<b-card-text>
						<b-row class="mb-2">
							<b-col sm="7">
								<b-form-group label="Company Code" label-cols-sm="6"
									label-class="font-weight-bold pt-0" label-align-sm="right">
									{{ row.item.brand }}
								</b-form-group>
								<b-form-group label="Branch Code" label-cols-sm="6" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									{{ row.item.branchCode ? row.item.branchCode : '-' }}
								</b-form-group>

								<b-form-group label="Storage Locations" label-cols-sm="6"
									label-class="font-weight-bold pt-0" label-align-sm="right">
									<span class="numFont">
										{{ row.item.noOfStorageLocations }}
									</span>
								</b-form-group>
								<b-form-group label="Inactive Locations" label-cols-sm="6"
									label-class="font-weight-bold pt-0" label-align-sm="right">
									<span class="numFont">
										{{ row.item.noOfInactiveNodes }}
									</span>
								</b-form-group>
								<b-form-group label="Users" label-cols-sm="6" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<span class="numFont">
										{{ row.item.noOfUsers }}
									</span>
								</b-form-group>
								<b-form-group label="Has 2-Way Auth?" label-cols-sm="6"
									label-class="font-weight-bold pt-0" label-align-sm="right">
									<span v-if="row.item.has2WayAuth">
										<b-badge variant="success">YES</b-badge>
									</span>
									<span v-else>
										<b-badge variant="secondary">NO</b-badge>
									</span>
								</b-form-group>


								<b-form-group label="Has Top Camera Access?" label-cols-sm="6"
									label-class="font-weight-bold pt-0" label-align-sm="right"
									v-if="loggedUser.type === 'Super Admin'">
									<span v-if="row.item.camera && row.item.camera.top">
										<b-badge variant="success">YES</b-badge>
									</span>
									<span v-else>
										<b-badge variant="secondary">NO</b-badge>
									</span>
								</b-form-group>
								<b-form-group label="Has Back Camera Access?" label-cols-sm="6"
									label-class="font-weight-bold pt-0" label-align-sm="right"
									v-if="loggedUser.type === 'Super Admin'">
									<span v-if="row.item.camera && row.item.camera.back">
										<b-badge variant="success">YES</b-badge>
									</span>
									<span v-else>
										<b-badge variant="secondary">NO</b-badge>
									</span>
								</b-form-group>
							</b-col>
							<b-col sm="3" v-if="hasCompanyLogo(row.item.companyLogo)">
								<b-img v-img-orientation-changer :src="row.item.companyLogo.url" alt="Responsive image"
									thumbnail fluid />
								<a href="#" class="pull-left"
									@click.prevent="openImage(row.item, row.item.companyLogo.url)">
									Click to view full image
								</a>
							</b-col>
						</b-row>
					</b-card-text>
				</b-tab>
				<b-tab title="Permissions" v-if="loggedUser.type === 'Super Admin'">
					<b-card-text>
						<b-form-group label="Content Management System" label-cols-sm="4"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							<span v-if="row.item.permissions && row.item.permissions.cms">
								<b-badge variant="success">Has Access</b-badge>
							</span>
							<span v-else>
								<b-badge variant="secondary">No Access</b-badge>
							</span>
						</b-form-group>
						<b-form-group label="Inventory" label-cols-sm="4" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span v-if="row.item.permissions && row.item.permissions.inventory">
								<b-badge variant="success">Has Access</b-badge>
							</span>
							<span v-else>
								<b-badge variant="secondary">No Access</b-badge>
							</span>
						</b-form-group>
						<b-form-group label="Dispatch " label-cols-sm="4" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span v-if="row.item.permissions && row.item.permissions.dispatch">
								<b-badge variant="success">Has Access</b-badge>
							</span>
							<span v-else>
								<b-badge variant="secondary">No Access</b-badge>
							</span>
						</b-form-group>
						<b-form-group label="Sticker Printer " label-cols-sm="4" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span v-if="row.item.permissions && row.item.permissions.sticker">
								<b-badge variant="success">Has Access</b-badge>
							</span>
							<span v-else>
								<b-badge variant="secondary">No Access</b-badge>
							</span>
						</b-form-group>
						<b-form-group label="Disposal and Repair " label-cols-sm="4" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span v-if="row.item.permissions && row.item.permissions.disposal">
								<b-badge variant="success">Has Access</b-badge>
							</span>
							<span v-else>
								<b-badge variant="secondary">No Access</b-badge>
							</span>
						</b-form-group>
					</b-card-text>
				</b-tab>
				<b-tab title="Change Logs">
					<CompanyChangeLogsDetailsView :row="row" />
				</b-tab>
			</b-tabs>
		</b-card>
		<CompanyLocationView :company="row.item" />
	</div>
</template>

<script>
// Component
import CompanyLocationView from './CompanyLocationView';
import CompanyChangeLogsDetailsView from './CompanyChangeLogsDetailsView.vue';

// Util
import { LocationUtil } from '@/utils/locationUtil';
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'company-details-view',
	components: {
		CompanyLocationView,
		CompanyChangeLogsDetailsView,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loggedUser: this.$store.getters.loggedUser,
		};
	},
	methods: {
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getCoordinates(geoaddress) {
			geoaddress = LocationUtil.getGeoaddress(geoaddress);

			return (
				'(' +
				geoaddress.latitude.toFixed(6) +
				', ' +
				geoaddress.longitude.toFixed(6) +
				')'
			);
		},
		showCompanyLocation(item) {
			this.$bvModal.show('company-location-view-' + item.id);
		},
		openImage(company, imageLink) {
			EventBus.$emit('onSelCompanyLogoImageView', {
				company: company,
				imageLink: imageLink,
			});
			this.$bvModal.show('company-logo-image-view');
		},
		hasCompanyLogo(companyLogo) {
			return companyLogo && !_.isEmpty(companyLogo);
		},
	},
};
</script>